import React, { Component } from 'react';
import "./styles.css";


interface IShareRRSS {
    url: string;
}

class ShareRRSS extends Component<IShareRRSS> {
    shareOnFacebook = () => {
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(this.props.url)}`, '_blank');
    };

    shareOnWhatsApp = () => {
        window.open(`https://api.whatsapp.com/send?text=${encodeURIComponent(this.props.url)}`, '_blank');
    };

    shareByEmail = () => {
        window.location.href = `mailto:?subject=Compartir&body=${encodeURIComponent(this.props.url)}`;
    };

    shareOnTwitter = () => {
        window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(this.props.url)}`, '_blank');
    };

    shareOnInstagram = () => {
        window.open('https://www.instagram.com/', '_blank');
    };

    render() {
        return (
            <div className='container-rrss'>
                <p> Comparte esto:</p>
                <div className="detail-rrss">
                <img className = "buttonStyle" src={require("../../assets/iconsRRSS/facebook.png")}  onClick={this.shareOnFacebook} />
                <img className = "buttonStyle" src={require("../../assets/iconsRRSS/whatsapp.png")} onClick={this.shareOnWhatsApp} />
                <img className = "buttonStyle" src={require("../../assets/iconsRRSS/gmail.png")} onClick={this.shareByEmail} />
                <img className = "buttonStyle" src={require("../../assets/iconsRRSS/x.png")} onClick={this.shareOnTwitter} />
                <img className = "buttonStyle" src={require("../../assets/iconsRRSS/instagram.png")} onClick={this.shareOnInstagram} />
                </div>
            </div>
        );
    }
}

export default ShareRRSS;
