import React from "react";
import { useLocation, useParams } from "react-router-dom";
import BlogList from "./BlogList";
import New from "../New/New";
import PostsList from "../../components/PostsList/PostsList";
import PostsProvider from "../../data/provider/PostsProvider";
import Search from "../Search/Search";

export default function BlogFactory() {
  const { pathname } = useLocation();
  const { category_slug, tag_slug } = useParams();

  const isNoticias = /\/noticias/.test(pathname);
  const isCategory = /\/category/.test(pathname);
  const isTag = /\/tag/.test(pathname);
  const isSearch = /\/search/.test(pathname);

  if (isNoticias) {
    return (
      <BlogList>
        <PostsList provider={new PostsProvider({ per_page: 15 })} paginationBasePath="/noticias" />
      </BlogList>
    );
  }

  if (isSearch) {
    return <Search />;
  }

  if (isCategory) {
    return (
      <BlogList>
        <PostsList
          provider={new PostsProvider({ category_slug: category_slug, per_page: 15 })}
          paginationBasePath={`/category/${category_slug}`}
        />
      </BlogList>
    );
  }

  if (isTag) {
    return (
      <BlogList>
        <PostsList
          provider={new PostsProvider({ tag_slug: tag_slug, per_page: 15 })}
          paginationBasePath={`/tag/${tag_slug}`}
        />
      </BlogList>
    );
  }

  return <New />;
}
