import { ReactNode } from "react";

export type ChildProp = {
  children?: ReactNode | ReactNode[];
};

export enum HomeSection {
  HOME = "home",
  DEVELOP = "desarrollo-de-software",
  CLOUD = "cloud",
  CONTACT = "contactar",
  STREAMING = "streaming",
  CERTIFICATIONS = "certificaciones",
  NEWS = "noticias",
  CLIENTS = "nuestros-clientes",
  CATEGORY = "category",
  NOTFOUND = "notfound",
  SEARCH = "search"
}
