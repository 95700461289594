import { useEffect, useState } from "react";

export default function useIsMobile(breakpoint: number = 768) {
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(true);

  //If mobile, do not autoplay
  useEffect(() => {
    const handleResize = () => {
      const isMobileOrTabletDevice = window.innerWidth < breakpoint; // Adjust the threshold as needed
      setIsMobileOrTablet(isMobileOrTabletDevice);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const direction: "horizontal" | "vertical" = isMobileOrTablet ? "vertical" : "horizontal";

  return { isMobileOrTablet, direction };
}
