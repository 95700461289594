import React from "react";
import { ChildProp } from "../../types/Types";
import "./styles.css";

interface FeaturedBoxProps {
  className?: string;
}

export default function FeaturedBox(props: FeaturedBoxProps & ChildProp) {
  return (
    <div className={`featured-box ${props.className}`}>{props.children}</div>
  );
}

FeaturedBox.RightContent = function (props: ChildProp) {
  return <div className="featured-box__right">{props.children}</div>;
};

FeaturedBox.LeftContent = function (props: ChildProp) {
  return <div className="featured-box__left">{props.children}</div>;
};
