import { faPlay, faPause } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import "./styles.css";
import "./responsive.css";

export default function FakePlayer() {
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    let intervalId = 0;
    if (playing) {
      intervalId = setInterval(() => {
        document.getElementById("streaming").scrollBy(0, 2);
      }, 50);
    }

    return () => clearInterval(intervalId);
  }, [playing]);

  const onClickPlay = () => {
    setPlaying(!playing);
  };

  return (
    <div className="fake-player">
      <div className="fake-player__play" onClick={onClickPlay}>
        {playing ? (
          <FontAwesomeIcon icon={faPause} />
        ) : (
          <FontAwesomeIcon icon={faPlay} />
        )}
      </div>
      <div className="fake-player__progress"></div>
    </div>
  );
}
