import React from "react";
import "./styles.css";
import "./responsive.css";
import BlogFactory from "./BlogFactory";
import WebSection from "../../components/WebSection/WebSection";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";
import { HomeSection } from "../../types/Types";
import Section from "../../components/Section/Section";
import Up from "../../components/Up/Up";

export default function Blog() {
  const navigate = useHistoryNavigator();

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate(`/category/blog`);
  };

  return (
    <WebSection id="blog" path={[HomeSection.NEWS, HomeSection.CATEGORY, HomeSection.SEARCH]} onClick={handleClick}>
      <Section>
        <BlogFactory />
  
      </Section>
      <Up slug="blog"/>
    </WebSection>
  );
}
