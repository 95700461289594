import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import "./responsive.css";
import Section from "../../components/Section/Section";
import ButtonClose from "../../components/ButtonClose/ButtonClose";
import "./styles.css";
import ShareRRSS from "../../components/ShareRRSS/ShareRRSS";
import Rrss from "../../components/Rss/Rrss";
import Circles from "../../components/Circles/Circles";
import useDataProvider from "../../hooks/UseDataProvider";
import Post from "../../data/models/Post";
import IDataProvider from "../../data/IDataProvider";
import PassThroughProvider from "../../data/provider/PassThroughProvider";
import PostProvider from "../../data/provider/PostProvider";
import LoadingDots from "../../components/LoadingDots/LoadingDots";

export default function New() {
  const location = useLocation();
  const post: Post = location.state?.post;
  const { slug } = useParams();
  const provider: IDataProvider<Post> = post ? new PassThroughProvider<Post>(post) : new PostProvider(slug);
  const { data, loadData } = useDataProvider(provider);

  useEffect(function scrollToTopBlog() {
    var blogSection = document.getElementById("blog");
    if (blogSection) {
      blogSection.scrollTo(0, 0);
    }
  });

  useEffect(
    function loadNewData() {
      loadData();
    },
    [slug]
  );

  function decodeHTML(htmlString: string) {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.documentElement.textContent;
  }

  return (
    <div id="new" style={{ position: "relative" }} onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      {data ? (
        <>
          <Circles />
          <Section>
            <div className="container-new">
              <div className="new-detail">
                <div className="new-title">{decodeHTML(data.title?.rendered)}</div>
                <p
                  dangerouslySetInnerHTML={{
                    __html: data.content.rendered,
                  }}
                />

                <ShareRRSS url={data.link} />
              </div>
            </div>
          </Section>
          <ButtonClose />
          <Rrss />
        </>
      ) : (
        <LoadingDots />
      )}
    </div>
  );
}
