import React from "react";
import Section from "../../components/Section/Section";
import "./styles.css";
import "./responsive.css";
import WebSection from "../../components/WebSection/WebSection";
import useDataProvider from "../../hooks/UseDataProvider";
import PageProvider from "../../data/provider/PageProvider";
import Page from "../../data/models/Page";
import LoadingDots from "../../components/LoadingDots/LoadingDots";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";
import { HomeSection } from "../../types/Types";
import Up from "../../components/Up/Up";

export default function Contact() {
  const navigate = useHistoryNavigator();

  const { data } = useDataProvider(new PageProvider<Page>(process.env.REACT_APP_CONTACTO_SLUG!));

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate("/contactar");
  };

  return (
    <WebSection id="contact" path={[HomeSection.CONTACT]} onClick={handleClick} >
      {data ? (
        <>
          <Section>
            <Section.Content>
              <div className="contact-wrapper">
                <div className="contact-content">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: data.content.rendered,
                    }}
                  />
                </div>
              </div>
            </Section.Content>
          </Section>
          <Up slug="contact"/>
        </>
      ) : (
        <LoadingDots />
      )}

    </WebSection>
  );
}
