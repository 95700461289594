import React from "react";
import "./styles.css";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  basePath: string;
}

export default function Pagination(props: PaginationProps) {
  const { totalPages, currentPage, basePath } = props;
  const navigate = useHistoryNavigator();

  const goPrev = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (currentPage > 1) {
      navigate(`${basePath}/page/${currentPage - 1}`);
    }
  };

  const goNext = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (currentPage < totalPages) {
      navigate(`${basePath}/page/${currentPage + 1}`);
    }
  };

  return (
    <div className="pagination">
      {currentPage > 1 && (
        <button onClick={goPrev} className="pagination-button">
          Anterior
        </button>
      )}
      {totalPages > 1 && <button className="pagination-button-number current">{currentPage}</button>}
      {currentPage < totalPages && (
        <button onClick={goNext} className="pagination-button">
          Siguiente
        </button>
      )}
    </div>
  );
}
