import React, { useState, useEffect } from "react";
import PostListItem from "../PostListItem/PostListItem";
import Post from "../../data/models/Post";
import "./styles.css";
import Pagination from "../Pagination/Pagination";
import useDataProvider from "../../hooks/UseDataProvider";
import IDataProvider from "../../data/IDataProvider";
import LoadingDots from "../LoadingDots/LoadingDots";
import PostsListModel from "../../data/models/PostsListModel";
import { useLocation, useNavigate, useParams } from "react-router-dom";

interface PostsListProps {
  provider: IDataProvider<PostsListModel>;
  paginationBasePath: string;
}


export default function PostsList(props: PostsListProps) {
  const { pageNumber } = useParams();
  const { pathname } = useLocation();
  const { paginationBasePath } = props;
  const [newPosts, setNewPosts] = useState<Post[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const { data, loadData } = useDataProvider(props.provider, parseInt(pageNumber || "1"));
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (data && data.posts.length >= 0) {
      setNewPosts((prevNewPosts) => [...prevNewPosts, ...data.posts]);
      setIsLoading(false);
      setTotalPages(data.totalPages || 1);
    }

  }, [data]);

  useEffect(
    function scrollToTopBlog() {
      var blogSection = document.getElementById("blog");
      if (blogSection) {
        blogSection.scrollTo(0, 0);
      }
    },
    [data]
  );

  useEffect(() => {
    setIsLoading(true);
    loadData(parseInt(pageNumber || "1"));
  }, [pathname]);

  return (
    <div>
      {isLoading ? (
        <LoadingDots />
      ) : (data && data.posts.length > 0 ? (
        <div className="notices-screen">
          <Pagination currentPage={parseInt(pageNumber || "1")} totalPages={totalPages} basePath={paginationBasePath} />

          {data.posts.map((post: Post, index: number) => (
            <PostListItem key={index} post={post} />
          ))}
          <Pagination currentPage={parseInt(pageNumber || "1")} totalPages={totalPages} basePath={paginationBasePath} />
        </div>
      ) : (
        <div className="notices-screen">
        <p>No se encontraron resultados.</p>
        </div>
      ))}
    </div>
  );
}
