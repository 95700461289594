import React from "react";
import Section from "../../../../components/Section/Section";
import { DesarrolloPage } from "../../../../data/models/DesarrolloPage";
import "./styles.css";
import WebMPlayer from "../../../../components/WebMPlayer/WebMPlayer";

import HugeWord from "../../../../components/HugeWord/HugeWord";
import { Card } from "../../../../components/Card/Card";
import Wave from "../../../../components/Wave/Wave";

export default function TitleDevelop(props: { data: DesarrolloPage }) {
    const { data } = props;

    return(
        <>
            <section className="section-first">
                <HugeWord>{data?.title.rendered}</HugeWord>
            </section>
        </>
    );
}