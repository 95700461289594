import React, { useEffect, useRef } from "react";
import RubikItem from "./RubikItem";


import "./styles.css";
import "./responsive.css";

const Rubik = ({project}) => {
  const nodesRef = useRef([]);

  useEffect(() => {
    const directions = { 0: "top", 1: "right", 2: "bottom", 3: "left" };
    const classNames = ["in", "out"]
      .map((p) => Object.values(directions).map((d) => `${p}-${d}`))
      .reduce((a, b) => a.concat(b));

    const getDirectionKey = (ev, node) => {
      const { width, height, top, left } = node.getBoundingClientRect();
      const l = ev.pageX - (left + window.pageXOffset);
      const t = ev.pageY - (top + window.pageYOffset);
      const x = l - (width / 2) * (width > height ? height / width : 1);
      const y = t - (height / 2) * (height > width ? width / height : 1);
      return Math.round(Math.atan2(y, x) / 1.57079633 + 5) % 4;
    };

    const itemMouseOver = (ev, element) => {
      element.classList.remove(...classNames);
      element.classList.add(`in-${directions[getDirectionKey(ev, element)]}`);
    };

    const itemMouseOut = (ev, element) => {
      element.classList.remove(...classNames);
      element.classList.add(`out-${directions[getDirectionKey(ev, element)]}`);
    };

    const ulNode = nodesRef.current;

    Array.from(ulNode.children).forEach((node) => {
      node.addEventListener("mouseover", (ev) => itemMouseOver(ev, node));
      node.addEventListener("mouseout", (ev) => itemMouseOut(ev, node));
    });

    return () => {
      Array.from(ulNode.children).forEach((node) => {
        node.removeEventListener("mouseover", (ev) => itemMouseOver(ev, node));
        node.removeEventListener("mouseout", (ev) => itemMouseOut(ev, node));
      });
    };
  }, []);

  return (
    <div className="mtRubik">
      <ul ref={(ul) => (nodesRef.current = ul)}>
        {project.map((item, index) => (
          <RubikItem key={index} title={item.nombre} text={item.descripcion} image={item.imagen}/>
        ))}
      </ul>
    </div>
  );
};

export default Rubik;
