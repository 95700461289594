import React from "react";
import "./styles.css";
import { DesarrolloPage } from "../../../../data/models/DesarrolloPage";
import Wave from "../../../../components/Wave/Wave";
import Title from "../../../../components/Title/Title";
import LogosTech from "../../../../components/LogosTech/LogosTech";


export default function Tecnology(props: { data: DesarrolloPage }) {

    const { data } = props;
    const logosArray = data?.acf.tecnologias.logos;

    return (
        <>
            {logosArray && logosArray.length > 0 && (
                <section className="section-third">
                    <Wave />
                    <div className="wrapper">
                        <Title>
                            <h2 className="animation-top-opacity">{data?.acf.tecnologias?.titular}</h2>
                            {data?.acf.tecnologias?.entradilla}
                        </Title>

                        <LogosTech logos={logosArray} />

                        <p style={{ textAlign: "center", marginTop: "100px", position: "relative", zIndex: "99" }}>
                            Y muchas más...
                        </p>
                    </div>
                </section>
            )}
        </>
    );

}