import React from 'react';
import Section from "../../components/Section/Section";
import Title from "../../components/Title/Title";
import ContactForm from "../../components/ContactForm/ContactForm";
import { ClientPage } from "../../data/models/ClientPage";

const ContactFormSection = ({data} : {data: ClientPage}) => {
    return (
        <Section className="section-second">
            <Section.Content>
                <div className="wrapper">
                    <Title>
                        <h2 className="typewriter-animation"> {data?.acf.contacto.titular}</h2>
                        <p className='form-description'>{data?.acf.contacto.entradilla}</p>
                    </Title>
                    <div className='content-form'>
                    <ContactForm className={"contact-form"} formUrl={process.env.REACT_APP_FORM_URL} />
                    </div>
                </div>
            </Section.Content>
        </Section>
    );
};

export default ContactFormSection;
