import API from "../API";
import IDataProvider from "../IDataProvider";
import FilterPost from "../models/FilterPost";
import Post from "../models/Post";

export default class PostProvider implements IDataProvider<Post> {
  filter: number | string;

  constructor(filter?: number | string) {
    this.filter = filter || "";
  }

  public async load(): Promise<Post | undefined> {
    let filter = this.createFilter();
    if (filter) {
      let post: Post;
      return new Promise<Post>(async (resolve, reject) => {
        try {
          const postData = await API.loadPost(filter);
          if (!isNaN(Number(this.filter))) {
            post = postData;
          } else {
            post = postData[0];
          }
          resolve(post);
        } catch (error) {
          console.error("Error cargando posts:", error);
          reject(error);
        }
      });
    }
    return undefined;
  }

  public createFilter(): string {
    let filter: string = "";
    if (this.filter && !isNaN(Number(this.filter))) {
      filter = `/${this.filter}`;
    } else if (this.filter) {
      filter = `?slug=${this.filter}`;
    }

    return filter;
  }
}
