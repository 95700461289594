import React, { AnchorHTMLAttributes, ReactNode } from "react";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";

export default function PageButton(props: {
  text: string;
  url: string;
  linkProps?: AnchorHTMLAttributes<HTMLAnchorElement>;
}) {
  const navigate = useHistoryNavigator();

  return (
    <div
      className="page-link"
      onClick={() => {
        navigate(props.url);
      }}
      {...props}
    >
      {props.text}
    </div>
  );
}
