import React from "react";
import palo from "../../assets/palo.png";
import ButtonClose from "../../components/ButtonClose/ButtonClose";
import Rrss from "../../components/Rss/Rrss";
import "./styles.css";
import "./responsive.css";
import AnimatedArrow from "./AnimatedArrow/AnimatedArrow";
import WebSection from "../../components/WebSection/WebSection";
import BubblesBackground from "./BubblesBackground/BubblesBackground";
import FooterPages from "../../components/FooterPages/FooterPages";
import PageButton from "../../components/PageButton/PageButton";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";
import ClipPaths from "./ClipPathsBrush/ClipPaths";
import useThemeColor from "../../hooks/useThemeColor";
import LogoHome from "../../components/LogoHome/LogoHome";

export default function Home(props) {
  const navigate = useHistoryNavigator();

  const handleClick = () => {
    navigate("/home");
  };

  useThemeColor();

  return (
    <div className="home-screen">
      <img className="palo" src={palo} alt="Palo" />
      <WebSection id="home" path={["home"]} onClick={handleClick}>
        <LogoHome />
        <ButtonClose />
        <Rrss />
        <BubblesBackground />
        {props.children}
      </WebSection>

      <AnimatedArrow />
      <div className="descubre">Toca y descubre qué hay dentro de Meytel</div>

      <FooterPages>
        <PageButton text="Kit Digital" url="/programa-kit-digital" className="kit-digital" />
        <PageButton text="Política de privacidad" url="/politica-de-privacidad" />
        <PageButton text="Aviso legal" url="/aviso-legal" />
        <PageButton text="Política de cookies" url="/cookie-policy" />
        <PageButton text="Política de seguridad" url="/politica-de-seguridad" />
        <PageButton text="Créditos y atribuciones" url="/creditos" />
      </FooterPages>

      <ClipPaths />
    </div>
  );
}
