import React from "react";


export default function RubikItem({ title, text, image }) {

  return (
    <li>
      <div className="mtRubik__base">
        <img src={image.url} alt="" />
      </div>
      <div className="mtRubik__info">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </li>
  );
}
