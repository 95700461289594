import { useLocation } from "react-router-dom";

export default function useThemeColor() {
  const { pathname } = useLocation();

  const setColorTheme = (color: string) => {
    var metaThemeColor = document.getElementById("theme-color-meta");
    metaThemeColor?.setAttribute("content", color);
  };

  switch (pathname) {
    case "/home":
      setColorTheme("#105a7b");
      break;
    case "/streaming":
      setColorTheme("#000000");
      break;
    case "/desarrollo-de-software":
      setColorTheme("#105a7b");
      break;
    case "/noticias":
    case "/certificaciones":
    case "/contactar":
      setColorTheme("#ffff");
      break;
    case "/clientes":
    case "/cloud":
      setColorTheme("#2078a1");
      break;
    default:
      setColorTheme("#ffff");
      break;
  }
}
