import API from "../API";
import IDataProvider from "../IDataProvider";
import FilterPost from "../models/FilterPost";
import PostsListModel from "../models/PostsListModel";

export default class PostsProvider implements IDataProvider<PostsListModel> {
  filter:FilterPost;

  constructor(filter: FilterPost) {
    this.filter = filter;
  }

  public async load(page:number): Promise<PostsListModel> {
      let filter = this.createFilter(page);
      return new Promise<PostsListModel>(async (resolve, reject) => {
          try {
            const postsData = await API.loadAllPosts(filter);
            const postsList: PostsListModel = {totalPosts:postsData.totalPosts,totalPages: postsData.totalPages,posts: postsData.data,
            };
            resolve(postsList);

          } catch (error) {
            console.error("Error cargando posts:", error);
            reject(error);
          }
        });
  }


  public createFilter(page: number): string {
    const filters: string[] = [];

    filters.push('?');
    if (this.filter.category) filters.push(`categories=${this.filter.category}`);
    if (this.filter.category_slug) filters.push(`category_slug=${this.filter.category_slug}`);
    if (this.filter.tag_slug) filters.push(`tag_slug=${this.filter.tag_slug}`);
    if (this.filter.per_page) filters.push(`per_page=${this.filter.per_page}`);
    if (this.filter.search) filters.push(`search=${this.filter.search}`);
    if (page) filters.push(`page=${page}`);

    return filters.join('&');
  }


}
