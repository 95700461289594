import { useEffect, useState } from "react";
import IDataProvider from "../data/IDataProvider";

/**
 * Receives a IDataProvider and dispatch pdate data when data is loaded
 * @param provider
 * @returns data, loaData, loadPerPage
 */
export default function useDataProvider<T>(
  provider: IDataProvider<T>,
  initialPage: number = 0
): { data: T | undefined; loadData: (page?: number) => Promise<void> } {
  const [data, setData] = useState<T>();

  const loadData = async (page: number = initialPage) => {
    const newData = await provider.load(page);
    setData(newData);
  };

  useEffect(() => {
    loadData(initialPage);
  }, []);

  return { data, loadData };
}
