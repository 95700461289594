import { DesarrolloPage } from "../../../../data/models/DesarrolloPage";
import React from "react";
import './styles.css';
import Wave from "../../../../components/Wave/Wave";
import Title from "../../../../components/Title/Title";
import Rubik from "../../../../components/Rubik/Rubik";

export default function Projects(props: { data: DesarrolloPage }) {

    const { data } = props;
    const projectData = data?.acf.proyectos.proyecto;

    return (
        <>
           {projectData && projectData.length > 0 && (
              <section className="section-fourth">
                <Wave inverse={true} />
                <div className="wrapper">
                  <Title>
                    <h2 className="typewriter-animation">{data?.acf.proyectos?.titular}</h2>
                    {/* {data?.acf.proyectos?.entradilla} */}
                  </Title>
                  <Rubik project={projectData} />
                </div>
              </section>
            )}
        </>
    );

}