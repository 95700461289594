import React, { LegacyRef, MutableRefObject, useEffect, useRef, useState } from "react";
import Text from "../../components/Text/Text";
import Rubik from "../../components/Rubik/Rubik";
import Title from "../../components/Title/Title";
import Section from "../../components/Section/Section";
import "./styles.css";
import "./responsive.css";
import WebSection from "../../components/WebSection/WebSection";
import useDataProvider from "../../hooks/UseDataProvider";
import PageProvider from "../../data/provider/PageProvider";
import { DesarrolloPage } from "../../data/models/DesarrolloPage";
import LoadingDots from "../../components/LoadingDots/LoadingDots";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";
import { HomeSection } from "../../types/Types";
import Servicios from "./Sections/Servicios/Servicios";
import Tecnology from "./Sections/Tecnology/Tecnology";
import Projects from "./Sections/Projects/Projects";
import TitleDevelop from "./Sections/Title/TitleDevelop";
import Up from "../../components/Up/Up";

export default function Develop() {
  const navigate = useHistoryNavigator();

  const { data } = useDataProvider(new PageProvider<DesarrolloPage>(process.env.REACT_APP_ID_DEVELOP!));

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate("/desarrollo-de-software");
  };

  return (
    <WebSection id="develop" path={[HomeSection.DEVELOP]} onClick={handleClick}>
      {data ? (
        <>
          <TitleDevelop data={data} />
          <Servicios data={data} />
          <Tecnology data={data} />
          <Projects data={data} />
          <Up slug="develop"/>
        </>
        
      ) : (
        <LoadingDots />
      )}

    </WebSection>
  );
}
