import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function useHistoryNavigator() {
  const { pathname } = useLocation();
  const navigator = useNavigate();

  const navigate = (path, options) => {
    if (path !== pathname) {
    const url = document.location;
    const { state, ...optionsWithoutState } = options || {};
    navigator(path, { ...optionsWithoutState, state: { from: url.pathname, ...(state || {}) } });
  }
  };

  return navigate;
}