import React from "react";
import WaveImage from "../../assets/fondos/wave.svg";
import "./styles.css";

export default function Wave(props: { inverse?: boolean }) {
  return (
    <img
      className={`wave ${props.inverse ? "inverse" : ""} `}
      src={WaveImage}
      alt=""
    />
  );
}
