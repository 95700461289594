import React from "react";
import { StreamingPage } from "../../../../data/models/StreamingPage";
import FeaturedBox from "../../../../components/FeaturedBox/FeaturedBox";
import "./styles.css";
import "./responsive.css";

export default function Providers(props: { data: StreamingPage }) {
  const { data } = props;
  return (
    <FeaturedBox className="providers">
      <FeaturedBox.RightContent>
        <div dangerouslySetInnerHTML={{ __html: data?.acf.provisionado || "" }} />
      </FeaturedBox.RightContent>
      <FeaturedBox.LeftContent>
        <div dangerouslySetInnerHTML={{ __html: data?.acf.caracteristicas || "" }} />
      </FeaturedBox.LeftContent>
    </FeaturedBox>
  );
}
