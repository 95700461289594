import IDataProvider from "../IDataProvider";
import Post from "../models/Post";

export default class PassThroughProvider<T> implements IDataProvider<T>  {
    private data: T; 

    constructor(data: T) {
        this.data = data;
      }
    load(page?: number): Promise<T> {
        return Promise.resolve(this.data);
    }
}