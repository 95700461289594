import API from "../API";
import IDataProvider from "../IDataProvider";
import Page from "../models/Page";

export default class PageProvider<T extends Page> implements IDataProvider<T>  {

  filter: number | string;

  constructor(filter: number | string) {
    this.filter = filter;
  }

  public async load(page?: number): Promise<T> {

    if (isNaN(this.filter as number)) {
      return this.loadBySlug(this.filter as string);
    }

    return this.loadById(this.filter as number);
  }



  public async loadById(id: number): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {
      try {
        const pageData = await API.loadPage(id);
        resolve(pageData);
      } catch (error) {
        console.error("Error cargando pagina:", error);
        reject(error);
      }
    });
  }


  public async loadBySlug(slug: string): Promise<T> {
    return new Promise<T>(async (resolve, reject) => {
      try {
        const pageData = await API.loadPageSlug("slug=" + slug);
        resolve(pageData[0]);
      } catch (error) {
        console.error("Error cargando pagina:", error);
        reject(error);
      }
    });
  }


}
