import { useNavigate, useHistory, useLocation, useNavigation } from "react-router-dom";
import "./styles.css";
import "./responsive.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export default function ButtonClose() {
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (e) => {
    e.stopPropagation();
    let previousUrl = location.state?.from || "/home";

    if (document.location.pathname == "/home") {
      previousUrl = "/";
    }

    navigate(previousUrl);
  };

  return (
    <div className="close" onClick={handleClick}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </div>
  );
}
