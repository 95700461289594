import React from "react";
import DestacadoItem from "./DestacadoItem";

import "./styles.css";
import "./responsive.css";

const Destacado = ({ lista }) => {
  return (
    <div className="slider">
      <div className="slide-track">
        {lista.map((item, index) => (
          <DestacadoItem key={index} texto={item.destacado} />
        ))}
      </div>
    </div>
  );
};

export default Destacado;
