import React from 'react';
import Section from "../../components/Section/Section";
import WebMPlayer from "../../components/WebMPlayer/WebMPlayer";
import HugeWord from "../../components/HugeWord/HugeWord";
import ClientSlider from "../../components/ClientSlider/ClientSlider";
import {ClientPage} from "../../data/models/ClientPage";
import Title from "../../components/Title/Title";

const ClientSliderSection = ({data, clientsWebM} : {data: ClientPage; clientsWebM: string}) => {
    return (
        <Section>
            <Section.Content>
                <div className={"section__content--first"}>
                    <div className="text-animation">
                        <Title>
                            <h1>Clientes</h1>
                        </Title>
                    </div>
                    <HugeWord>{data?.title.rendered}</HugeWord>
                    <ClientSlider entradilla_copiar={data?.acf.entradilla} />
                </div>
        </Section.Content>

    </Section>


    );
};


export default ClientSliderSection;
