import React, { useState } from "react";

import "./styles.css";
import BlogTitle from "./BlogTitle/BlogTitle";
import BlogTabs, { BlogTab } from "./BlogTabs/BlogTabs";
import { ChildProp } from "../../types/Types";
import Searcher from "../../components/Searcher/Searcher";

export default function BlogList(props: ChildProp) {
  return (
    <div onClick={(e: React.MouseEvent) => e.stopPropagation()}>
      <BlogTitle />

      <BlogTabs>
        <BlogTab title="Noticias" url="/category/blog"></BlogTab>
        <BlogTab title="Malware Connection" url="/category/malware-connection"></BlogTab>
        <BlogTab title="Desarrollo de software" url="/category/desarrollo-software"></BlogTab>
        <BlogTab title="Servicios gestionados" url="/category/servicios-gestionados"></BlogTab>
        <Searcher />
      </BlogTabs>
      {props.children}
    </div>
  );
}
