import React, { HTMLProps, ReactElement, ReactNode, useEffect, useState } from "react";
import useActiveSection from "../../hooks/useActiveSection";
import { ChildProp, HomeSection } from "../../types/Types";


interface WebSectionProps extends ChildProp {
  id: string;
  onClick: (e: React.MouseEvent) => void;
  path: HomeSection[];
}

export default function WebSection(props: WebSectionProps) {
  const { activeSection } = useActiveSection(props.path);
  const [deferedaActiveSection, setDeferedaActiveSection] = useState(activeSection);

  //Evita que se oculte en contenido nada más tocar el "Cerrar"
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (!activeSection) {
      timeoutId = setTimeout(() => {
        setDeferedaActiveSection(activeSection);
      }, 1000);
    } else {
      setDeferedaActiveSection(activeSection);
    }
    return () => clearTimeout(timeoutId);
  }, [activeSection]);

  return (
    <div
      id={props.id}
      className={`section ${activeSection ? "active" : ""}  ${
        deferedaActiveSection && !activeSection ? "deactivating" : ""
      }`}
      onClick={props.onClick }
    >
      {deferedaActiveSection && props.children}
    </div>
  );
}
