import React, { useState } from "react";
import "./style.css";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";

export default function Searcher() {
  const navigate = useHistoryNavigator();
  const [active, setActive] = useState(false);
  const { pathname } = useLocation();

  const setSearchState = (text: string) => {
    navigate("/search/" + text);
  };

  const handleSearchClick = () => {
    setActive(!active);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      const searchText = (event.target as HTMLInputElement).value.trim();
      if (searchText !== "") {
        setSearchState(searchText);
        setActive(false);
      }
    }
  };

  return (
    <div className="searcher">
      <FontAwesomeIcon icon={faSearch} onClick={handleSearchClick} />

      <div className={`searcher-layer${active ? " active" : ""}`}>
        <div className="search-input-wrapper">
          <input type="search" onKeyPress={handleKeyPress} />
          <button className="close-searcher" onClick={handleSearchClick}>
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
}
