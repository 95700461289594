import React, { useState, useEffect } from "react";
import './styles.css';
import flechaup from "../../assets/flechaup.png";


export default function Up({ slug }: { slug: string }) {
    const [showButton, setShowButton] = useState(false);

    const handleScroll = () => {
        const scrollPosition = document.getElementById(slug)?.scrollTop;
        setShowButton(scrollPosition! > 100);
    };

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();

        const slugElement = document.getElementById(slug);
        if (slugElement) {
            slugElement.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        document.getElementById(slug)?.addEventListener('scroll', handleScroll);
        return () => {
            document.getElementById(slug)?.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`scroll-to-top ${showButton ? 'visible' : ''}`} onClick={handleClick}>
            <div className="arrow-container">
                <div className="arrow up" />
                <div className="arrow up" />
            </div>
        </div>
    );
}
