import React from "react";
import Section from "../../components/Section/Section";
import HugeWord from "../../components/HugeWord/HugeWord";
import "./styles.css";
import "./responsive.css";
import WebSection from "../../components/WebSection/WebSection";
import FeaturedBox from "../../components/FeaturedBox/FeaturedBox";
import useDataProvider from "../../hooks/UseDataProvider";
import PageProvider from "../../data/provider/PageProvider";
import { CloudPage } from "../../data/models/CloudPage";
import LoadingDots from "../../components/LoadingDots/LoadingDots";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";
import { HomeSection } from "../../types/Types";
import Destacado from "../../components/Destacado/Destacado";
import Cursor from "../../components/Cursor/Cursor";
import Up from "../../components/Up/Up";


export default function Cloud() {
  const navigate = useHistoryNavigator();

  const { data } = useDataProvider(new PageProvider<CloudPage>(process.env.REACT_APP_ID_CLOUD!));

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate("/cloud");
  };

  return (
    <WebSection id="cloud" path={[HomeSection.CLOUD]} onClick={handleClick} >
      <Cursor/>
      {data ? (
        <>
          <section>

          <div className="nube"></div>
          <div className="nube"></div>
          <div className="nube"></div>

            <Section.Content>
              <HugeWord>{"Servicios_Cloud"}</HugeWord>
              <div className="ellipses-container">
                <span className="span1">Alojamiento</span>
                <span className="span2">Colocacion</span>
                <span className="span3">IAAS</span>
                <span className="span4">PAAS</span>

                <h2 className="greeting">CLOUD</h2>
                <div className="ellipses ellipses__outer--thin">
                  <div className="ellipses ellipses__orbit"></div>
                </div>
                <div className="ellipses ellipses__outer--thick"></div>
              </div>
            </Section.Content>
          </section>

          <section className="sectionSecond">
            <Section.Content>
              <FeaturedBox className="destacado-cloud">
                <FeaturedBox.RightContent>
                  <img src={data?.acf.entradilla.imagen.url} />
                </FeaturedBox.RightContent>
                <FeaturedBox.LeftContent>
                  <h2>{data?.acf.entradilla.titular}</h2>
                  <p>{data?.acf.entradilla.descripcion}</p>
                </FeaturedBox.LeftContent>
              </FeaturedBox>
            </Section.Content>
          </section>
          {Array.isArray(data.acf.destacado.servicios) && data.acf.destacado.servicios.length > 0 && (
            <Destacado lista={data.acf.destacado.servicios} />
          )}
          <section className="sectionThird">
     
          <div className="nube"></div>
          <div className="nube"></div>
          <div className="nube"></div>
      
            <Section.Content>
              <FeaturedBox className="destacado-cloud">
                <FeaturedBox.RightContent>
                  <h2>{data?.acf.oferta.titular}</h2>
                  <p>{data?.acf.oferta.descripcion}</p>
                </FeaturedBox.RightContent>
                <FeaturedBox.LeftContent>
                  <img src={data?.acf.oferta.imagen.url} />
                </FeaturedBox.LeftContent>
              </FeaturedBox>
            </Section.Content>
            <Up slug="cloud"/>
          </section>
        </>
        
      ) : (
        <LoadingDots />
      )}
   
    </WebSection>
  );
}
