import React from 'react';
import './styles.css';
import './responsive.css';

type LogosTechProps = {
  logos: Array<{
    id: number;
    url: string;
  }>;
};

const LogosTech: React.FC<LogosTechProps> = ({ logos }) => {

  return (
    <div className="mtLogos">
      <div className="mtLogos__container">
        {[5, 6, 5].map((columnSize, columnIndex) => {
          const startIndex = columnIndex === 0 ? 0 : [5, 6].slice(0, columnIndex).reduce((acc, val) => acc + val, 0);
          const endIndex = startIndex + columnSize;

          return (
            <ul key={columnIndex} className="logos logos-border">
              {logos.slice(startIndex, endIndex).map((logo, index) => (
                <li key={index}>
                  <img alt={`Media ${startIndex + index + 1}`} src={logo.url} />
                </li>
              ))}
            </ul>
          );
        })}
      </div>
    </div>
  );

};

export default LogosTech;
