import React from "react";
import "./styles.css";
import "./responsive.css";
import WebSection from "../../components/WebSection/WebSection";
import useDataProvider from "../../hooks/UseDataProvider";
import PageProvider from "../../data/provider/PageProvider";
import { ClientPage } from "../../data/models/ClientPage";
import LoadingDots from "../../components/LoadingDots/LoadingDots";
import clientsWebM from "../../assets/video/clients.webm";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";
import { HomeSection } from "../../types/Types";
import ClientSliderSection from "./ClientsSliderSection";
import ContactFormSection from "./ContactFormSection";
import Wave2Svg from "../../components/Wabe2Svg/Wabe2Svg";
import Up from "../../components/Up/Up";

export default function Clients() {
  const navigate = useHistoryNavigator();

  const { data } = useDataProvider(new PageProvider<ClientPage>(process.env.REACT_APP_ID_CLIENT!));

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate("/nuestros-clientes");
  };

  return (
    <WebSection id="clients" path={[HomeSection.CLIENTS]} onClick={handleClick} >
      {data ? (
        <>
          <ClientSliderSection data={data} clientsWebM={clientsWebM} />
          <Wave2Svg />
          <ContactFormSection data={data} />
        </>
      ) : (
        <LoadingDots />
      )}
      <Up slug="clients" />
    </WebSection>
  );
}
