import React from "react";
import { ChildProp } from "../../types/Types";
import "./styles.css";

interface FlipCardProps2 {
  className?: string;
}

export default function FlipCard2(props: FlipCardProps2 & ChildProp) {
  return (
    <div className="card">
      <div className="card-content">{props.children}</div>
    </div>
  );
}

FlipCard2.Front = function (props: ChildProp) {
  return <div className="card-front">{props.children}</div>;
};

FlipCard2.Back = function (props: ChildProp) {
  return <div className="card-back">{props.children}</div>;
};
