import React, { useEffect, useRef, useState } from "react";
import {
  DotLottieCommonPlayer,
  DotLottiePlayer,
  DotLottiePlayerState,
  PlayerEvents,
  PlayerState,
  Props,
} from "@dotlottie/react-player";
import useIsMobile from "../hooks/useIsMobile";

export default function Lottie(props: Props) {
  const { isMobileOrTablet } = useIsMobile();
  const dotLottiePlayerRef = useRef<DotLottieCommonPlayer | null>(null);

  return (
    <DotLottiePlayer
      ref={dotLottiePlayerRef}
      onEvent={(event) => {
        if (event === PlayerEvents.Ready) dotLottiePlayerRef?.current?.seek(60);
      }}
      className={props.className || ""}
      src={props.src}
      autoplay={isMobileOrTablet ? false : props.autoplay}
      loop
      key={"mobile_" + isMobileOrTablet}
    ></DotLottiePlayer>
  );
}
