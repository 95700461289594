import "./App.css";
import "./styles/common.css";
import "./styles/responsive.css";

import Layout from "./screens/Layout";
import Home from "./screens/Home/Home";
import Clients from "./screens/Clients/Clients";
import Cloud from "./screens/Cloud/Cloud";
import Develop from "./screens/Develop/Develop";
import Streaming from "./screens/Streaming/Streaming";
import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Fragment } from "react";
import Blog from "./screens/Blog/Blog";
import { QueryClient, QueryClientProvider } from "react-query";
import Certificaciones from "./screens/Certificaciones/Certificaciones";
import NotFound from "./screens/NotFound/NotFound";

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <Fragment>
          <Routes>
            <Route
              path="/people/software/"
              element={<Navigate to="/desarrollo-de-software" />}
            />

            <Route path="/" element={<Layout />}>
              <Route path="/home" element={<Home />} />
              <Route path="/desarrollo-de-software" element={<Develop />} />
              <Route path="/cloud" element={<Cloud />} />
              <Route path="/nuestros-clientes" element={<Clients />} />
              <Route path="/streaming" element={<Streaming />} />
              <Route path="/certificaciones" element={<Certificaciones />} />

              <Route path="/" element={<Blog />}>
                <Route path="noticias/page/:pageNumber" element={<Blog />} />

                <Route path="category/:category_slug" element={<Blog />}>
                  <Route path="page/:pageNumber" element={<Blog />} />
                </Route>

                <Route path="tag/:tag_slug" element={<Blog />}>
                  <Route path="page/:pageNumber" element={<Blog />} />
                </Route>

                <Route path="search/:query" element={<Blog />}>
                  <Route path="page/:pageNumber" element={<Blog />} />
                </Route>

                <Route path=":slug" element={<Blog />} />
              </Route>
            </Route>

            <Route path="*" element={<Navigate to="/notfound" />} />
            <Route path="/notfound" element={<NotFound />} />
          </Routes>
        </Fragment>
      </Router>
    </QueryClientProvider>
  );
}

export default App;
