
import React from "react";
import './styles.css';

export default function LoadingDots() {
    return (
        <div className="loading-dots">
        <span>.</span>
        <span>.</span>
        <span>.</span>
      </div>
    );
}