import React from "react";
import "./styles.css";
import "./responsive.css";
import notfound from "../../assets/lottie/notfound.json";
import WebSection from "../../components/WebSection/WebSection";
import Section from "../../components/Section/Section";
import Lottie from "../../components/Lottie";
import Circles from "../../components/Circles/Circles";
import ButtonClose from "../../components/ButtonClose/ButtonClose";
import Rrss from "../../components/Rss/Rrss";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";
import { HomeSection } from "../../types/Types";
import LogoHome from "../../components/LogoHome/LogoHome";

export default function NotFound() {
  const navigate = useHistoryNavigator();

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleNavigateHome = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate("/home");
  };

  return (
    <WebSection id="notfound" path={[HomeSection.NOTFOUND]} onClick={handleClick} >
     <LogoHome/>
      <Circles></Circles>
      <Section>
        <Section.Title>Error</Section.Title>
        <Section.Content>
          <div className="content-notfound">
            <Lottie className="animation-lottie lottie-notfound" src={notfound} autoplay loop></Lottie>
            <div className="container-notfound">
              <div className="text-notfound">La página que intentas solicitar no se encuentra</div>
              <button className="button-notfound" onClick={handleNavigateHome}>
                Navega a Inicio
              </button>
            </div>
          </div>
        </Section.Content>
      </Section>
      <Rrss />
    </WebSection>
  );
}
