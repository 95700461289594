import React from "react";
import Section from "../../../../components/Section/Section";
import { CertificacionPage } from "../../../../data/models/CertificacionPage";
import Title from "../../../../components/Title/Title";
import FlipCard from "../../../../components/FlipCard/FlipCard";
import FlipCard2 from "../../../../components/FlipCard2/FlipCard2";

export default function Fabricantes(props: { data: CertificacionPage }) {
    const { data } = props;

    return (
        <>
          {data?.acf.fabricantes.fabricante && data?.acf.fabricantes.fabricante.length > 0 && (
              <Section className="certificaciones-logos">
                <div className="wrapper">
                  <Title>
                    <h2 className="typewriter-animation"> {data?.acf.fabricantes.titular}</h2>
                    <p>{data?.acf.fabricantes.entradilla}</p>
                  </Title>
                </div>
                <div className="certifications-builders">
                  {data?.acf.fabricantes.fabricante.map(
                    (fabricante: { logo: string; descripcion: string }, index: number) => (
                      <FlipCard2 key={index}>
                        <FlipCard2.Front>
                          <img src={fabricante.logo} alt={`Fabricante ${index + 1}`} />
                        </FlipCard2.Front>
                        <FlipCard2.Back>
                          <div dangerouslySetInnerHTML={{ __html: fabricante.descripcion }} />
                        </FlipCard2.Back>
                      </FlipCard2>
                    )
                  )}
                </div>
              </Section>
            )}
        </>
    );
}