import React, { useEffect, useState } from "react";
import "./styles.css";

const Cursor = () => {
const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

useEffect(() => {
    const handleMouseMove = (e) => {
    setCursorPosition({ x: e.clientX, y: e.clientY });
};

    document.addEventListener("mousemove", handleMouseMove);

    return () => {
    document.removeEventListener("mousemove", handleMouseMove);
    };
}, []);

const text = " Meytel it's simple! ";

const transformedText = text.split("").map((char, i) => (
    <span key={i} style={{ transform: `rotate(${i * 18}deg)` }}>
    {char}
    </span>
));

const circleStyle = {
    left: cursorPosition.x - 75 + "px",
    top: cursorPosition.y - 75 + "px",
};

return (
    <div className="circle" style={circleStyle}>
    <div className="text">{transformedText}</div>
    </div>
);
};

export default Cursor;
