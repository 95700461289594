import React from "react";
import { ChildProp } from "../../types/Types";
import "./styles.css";

interface SectionProps {
  className?: string;
}

export default function Section(props: SectionProps & ChildProp) {
  return <section className={props.className}>{props.children}</section>;
}

Section.Title = function (props: ChildProp) {
  return <div className="section__title">{props.children}</div>;
};

Section.Content = function (props: ChildProp) {
  return <div className="section__content">{props.children}</div>;
};
