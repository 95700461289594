import React from "react";
import Section from "../../../../components/Section/Section";
import { DesarrolloPage } from "../../../../data/models/DesarrolloPage";
import { Card } from "../../../../components/Card/Card";
import "./styles.css";
import "./responsive.css";
import WebMPlayer from "../../../../components/WebMPlayer/WebMPlayer";
import developWebM from "../../../../assets/video/develop.webm";
import Wave from "../../../../components/Wave/Wave";
export default function Servicios(props: { data: DesarrolloPage }) {
  const { data } = props;

  return (
    <Section className="section-second">
        <Wave inverse={true}/>
      <div className="container-title-service">
        <Section.Title>{data?.acf.servicios.titular}</Section.Title>
        <WebMPlayer className="develop-player" src={developWebM} autoPlay={true} muted={true} loop={true} />
      </div>
      <Section.Content>
        <div className="wrapper">
          {data?.acf.servicios.servicio.map((servicio, index) => (
            <div key={index} className="service-container">
              <div className="clip-path">
                <div className={`service-title animation-left ${index % 2 === 0 ? '' : 'service-title animation-right'}`}>{servicio.servicio_ofrecido}</div>
              </div>
            </div>
          ))}
        </div>
      </Section.Content>
    </Section>
  );
}
