import React, {useState} from 'react';
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/virtual';
import {Autoplay, EffectCards, EffectCoverflow, Navigation, Pagination, Thumbs} from "swiper/modules";

export interface ClientSliderProps {
    entradilla_copiar: string[]
}

const ClientSlider = (props: ClientSliderProps) => {
    const [panels, setPanels] = useState(props.entradilla_copiar);

    const pagination = {
        clickable: true,
        renderBullet: function (index: number, className: string) {
            return '<span class="' + className + '">' + (index + 1) + '</span>';
        },
    };

    return (
        <>
          <Swiper effect={'coverflow'}
                  autoplay={{
                      delay: 5000,
                      disableOnInteraction: false,
                  }}
                  rewind={true}
                  pagination={pagination}
                  centeredSlides={true}
                  slidesPerView={'auto'}
                  coverflowEffect={{
                      rotate: 50,
                      stretch: 0,
                      depth: 100,
                      modifier: 1,
                      slideShadows: true,
                  }}
                  modules={[EffectCoverflow, Pagination, Autoplay, Navigation]}>
              {panels && panels.map((panel:any, index:number) =>
                  <SwiperSlide className={"clients-slide"} key={index}>
                      <div dangerouslySetInnerHTML={{ __html: panel.entradilla_card }} />
                  </SwiperSlide>)
              }
          </Swiper>
        </>
    );
};

export default ClientSlider;
