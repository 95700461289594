import React from "react";
import "./styles.css";
import Post from "../../data/models/Post";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";

interface PostListItemProps {
  post: Post;
}

export default function PostListItem(props: PostListItemProps) {
  const navigate = useHistoryNavigator();
  const { post } = props;

  const getMonthFromDate = (dateString: string) => {
    const dateObject = new Date(dateString);
    const monthNames = ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"];
    return monthNames[dateObject.getMonth()];
  };

  const getDayFromDate = (dateString: string) => {
    const dateObject = new Date(dateString);
    return dateObject.getDate();
  };

  const getYearFromDate = (dateString: string) => {
    const dateObject = new Date(dateString);
    return dateObject.getFullYear();
  };

  function decodeHTML(htmlString:string) {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.documentElement.textContent;
  }
  

  return (
    !post.title.rendered ? null :
    <div key={post.id} className="post-container">
      <div className="post-time">
        <span className="time_month">{getMonthFromDate(post.date)}</span>
        <span className="time_day">{getDayFromDate(post.date)}</span>
        <span className="time_year">{getYearFromDate(post.date)}</span>
      </div>
      <div className="post-details">
        <h3
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/${post.slug}`, { state: { post } });
          }}
        >
          {decodeHTML(post.title.rendered)}
        </h3>
        <p>{decodeHTML(post.excerpt.rendered)}</p>
        <button
          className="read-more"
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/${post.slug}`, { state: { post } });
          }}
        >
          Continuar leyendo...
        </button>
      </div>
    </div>
  );
  
        
}
