import React, { useState, useEffect } from 'react';

interface UseResizableIframeProps {
    initialHeight: number;
}

const useResizableIframe = ({ initialHeight }: UseResizableIframeProps) => {
    const [iframeHeight, setIframeHeight] = useState<number>(initialHeight);

    const calculateIframeHeight = () => {
        // @ts-ignore
        return window.innerWidth <= 480 ? 520 : 500;
    };

    const handleResize = () => {
        setIframeHeight(calculateIframeHeight());
    };

    useEffect(() => {
        // @ts-ignore
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            // @ts-ignore
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return { iframeHeight };
};

export default useResizableIframe;
