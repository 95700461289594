import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookF,
  faXTwitter,
  faLinkedinIn,
  faPinterestP,
} from "@fortawesome/free-brands-svg-icons";

import "./styles.css";
import "./responsive.css";


export default function Rrss() {
  return (
    <div className="rrss">
      <a href="https://es.linkedin.com/company/meytel" target="_blank" >
        <FontAwesomeIcon icon={faLinkedinIn} />
      </a>
      <a href="https://www.facebook.com/people/Meytel/100066640463411/" target="_blank" >
        <FontAwesomeIcon icon={faFacebookF} />
      </a>
      <a href="https://twitter.com/meytel" alt="Compartir en X" >
        <FontAwesomeIcon icon={faXTwitter} />
      </a>
      <a href="https://www.pinterest.es/meytel/" target="_blank" >
        <FontAwesomeIcon icon={faPinterestP} />
      </a>
      <a href="mailto:info@meytel.net">
        <FontAwesomeIcon icon={faEnvelope} />
      </a>
      <a href="tel:911080308">
        <FontAwesomeIcon icon={faPhone} />
      </a>
    </div>
  );
}
