import React, { Fragment } from "react";
import "./styles.css";
import "./responsive.css";
import WebSection from "../../components/WebSection/WebSection";
import useDataProvider from "../../hooks/UseDataProvider";
import PageProvider from "../../data/provider/PageProvider";
import { CertificacionPage } from "../../data/models/CertificacionPage";
import LoadingDots from "../../components/LoadingDots/LoadingDots";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";
import { HomeSection } from "../../types/Types";
import Fabricantes from "./Sections/Fabricantes/Fabricates";
import TituloCertificaciones from "./Sections/TituloCertificaciones/TituloCertificaciones";
import Destacado from "../../components/Destacado/Destacado";
import Up from "../../components/Up/Up";

interface CertificacionesProps {}

const Certificaciones: React.FC<CertificacionesProps> = () => {
  const navigate = useHistoryNavigator();

  const { data } = useDataProvider(new PageProvider<CertificacionPage>(process.env.REACT_APP_ID_CERTIFICATION!));

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate("/certificaciones");
  };

  return (
    <WebSection id="certifications" path={[HomeSection.CERTIFICATIONS]} onClick={handleClick} >
      {data ? (
        <>
          <Fragment>
            <TituloCertificaciones data={data} />
            <Destacado
              lista={[
                { destacado: "MongoDB" },
                { destacado: "Sonicwall" },
                { destacado: "Barracuda" },
                { destacado: "Paessler" },
                { destacado: "Huawei" },
                { destacado: "QSAN" },
                { destacado: "Drobo" },
                { destacado: "DELL" },
                { destacado: "LockStep" },
                { destacado: "Digicert" },
                { destacado: "Paypal" },
                { destacado: "Kaspersky Labs" },
                { destacado: "Manage Engine" },
                { destacado: "SentinelOne" },
                { destacado: "Hornet Security" },
              ]}
            />
            <Fabricantes data={data} />
          </Fragment>
          <Up slug="certifications"/>

        </>
      ) : (
        <LoadingDots />
      )}
  
    </WebSection>
  );
};

export default Certificaciones;
