import React from "react";
import Home from "./Home/Home";
import Cloud from "./Cloud/Cloud";
import Streaming from "./Streaming/Streaming";
import Clients from "./Clients/Clients";
import Develop from "./Develop/Develop";
import RotateText from "./Home/RotateText/RotateText";
import Certificaciones from "./Certificaciones/Certificaciones";
import Blog from "./Blog/Blog";
import HideOnMobile from "../components/ResponsiveHelpers/HideOnMobile";
import Contact from "./Contacto/Contacto";

export default function Layout() {
  return (
    <div className="root-component">
      <Home>
        <Cloud />
        <Streaming />
        <Clients />
        <Develop />
        <Certificaciones />
        <Blog />
        <Contact />
      </Home>
    </div>
  );
}
