import React from "react";
import { StreamingPage } from "../../../../data/models/StreamingPage";
import "./styles.css";
import "./responsive.css";
import FeaturedBox from "../../../../components/FeaturedBox/FeaturedBox";

export default function LiveOnDemand(props: { data: StreamingPage }) {
    const { data } = props;
    return (
        <FeaturedBox className="livedemand">
            <FeaturedBox.RightContent>
                <div dangerouslySetInnerHTML={{ __html: data?.acf.entradilla.texto || "" }} />
            </FeaturedBox.RightContent>
            <FeaturedBox.LeftContent>
                <img src={data?.acf.entradilla.imagen} />
            </FeaturedBox.LeftContent>
        </FeaturedBox>
    );
}
