import React from "react";
import HugeWord from "../../../../components/HugeWord/HugeWord";
import Section from "../../../../components/Section/Section";
import { DesarrolloPage } from "../../../../data/models/DesarrolloPage";
import FeaturedBox from "../../../../components/FeaturedBox/FeaturedBox";
import WebMPlayer from "../../../../components/WebMPlayer/WebMPlayer";
import certificacionesWebM from "../../../../assets/video/certificaciones.webm";
import { CertificacionPage } from "../../../../data/models/CertificacionPage";

export default function TituloCertificaciones(props: { data: CertificacionPage }) {
  const { data } = props;

  return (
    <>
      <Section>
        <HugeWord>{data?.title.rendered}</HugeWord>
        <Section.Title>{data?.title.rendered}</Section.Title>
        <Section.Content>
          <FeaturedBox className="certificacion">
            <FeaturedBox.LeftContent>
              <div dangerouslySetInnerHTML={{ __html: data?.acf.entradilla }} />
            </FeaturedBox.LeftContent>
          </FeaturedBox>
        </Section.Content>
      </Section>
    </>
  );
}
