import React, { ReactNode, useEffect, useState } from "react";
import "./styles.css";
import "./responsive.css";
import useHistoryNavigator from "../../../hooks/useHistoryNavigator";
import { useLocation } from "react-router-dom";

export default function BlogTabs(props: { children: ReactNode | (ReactNode[] & typeof BlogTab) }) {
  return <div className="blog-tabs">{props.children}</div>;
}

export function BlogTab(props: { title: string; url: string }) {
  const navigate = useHistoryNavigator();
  const { pathname } = useLocation();
  const [active, setActive] = useState(false);

  useEffect(
    function setActiveClass() {
      setActive(pathname.includes(props.url));
    },
    [pathname]
  );

  return (
    <button
      className={`blog-tab-button ${active ? "active" : ""}`}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        navigate(props.url);
      }}
    >
      {props.title}
    </button>
  );
}
