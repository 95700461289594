import React from "react";
import "./styles.css";


export default function StreamTimeLineHit({ imagen, year, titular, descripcion }) {




  return (
    <div className="stream-timeline__hit">
      <div className="hit_image">
        <img src={imagen} alt={titular} />
      </div>
      {year && <div className="hit_date">{year}</div>}
      <div className="hit__title">{titular}</div>
      <div className="hit_description">{descripcion}</div>
    </div>
  );
}
