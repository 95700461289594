import React, { CSSProperties, HTMLAttributes } from "react";
import { ChildProp } from "../../types/Types";

interface WebMPlayerProps extends ChildProp {
  src: string;
  autoPlay?: boolean;
  muted?: boolean;
  loop?: boolean;
  className?: string;
  style?: CSSProperties;
}

export default function WebMPlayer(props: WebMPlayerProps) {
  return (
    <video
      autoPlay={props.autoPlay}
      playsInline={true}
      muted={props.muted}
      loop={props.loop}
      className={"video-player-webm " + props.className || ""}
      style={props.style}
    >
      <source src={props.src} type="video/webm" />
    </video>
  );
}
