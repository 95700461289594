import React from "react";
import WebSection from "../../components/WebSection/WebSection";
import { useParams } from "react-router-dom";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";
import ButtonClose from "../../components/ButtonClose/ButtonClose";
import Rrss from "../../components/Rss/Rrss";
import "./style.css";
import PostsList from "../../components/PostsList/PostsList";
import PostsProvider from "../../data/provider/PostsProvider";
import FilterPost from "../../data/models/FilterPost";
import { HomeSection } from "../../types/Types";
import BlogList from "../Blog/BlogList";

export default function Search() {

  let { query } = useParams();

  const searchFilter: FilterPost = {per_page: 15, search: query };

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <WebSection id="search" path={[HomeSection.SEARCH]} onClick={handleClick}>
      
        <div className="section__title search_title">
        <h2 className="search-title">Resultados de: {query}</h2>
        <BlogList />
 
    </div>
  
      <PostsList
        provider={new PostsProvider(searchFilter)}
        paginationBasePath={`/search/${query}`}
      />
      <ButtonClose />
      <Rrss />
    </WebSection>
  );
}
