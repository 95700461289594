import React from "react";
import "./styles.css";
import useResizableIframe from "../../hooks/useResizableIframe";

interface ContactFormProps {
    className?: string;
    formUrl?: string;
}

const ContactForm = (props: ContactFormProps) => {

    const { iframeHeight } = useResizableIframe({ initialHeight: 550 });

    return (
        <div className={props.className}>
            <iframe
                id="contact-form__iframe"
                width="600"
                height={iframeHeight.toString()}
                src={props.formUrl}
            ></iframe>
        </div>
    );
};

export default ContactForm;
