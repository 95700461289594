// API.ts

import User from "./models/User";
import Cache from "./Cache";
import { Query, QueryCache, QueryClient } from "react-query";

/**
 * API class for handling data fetching and caching.
 */
export default class API {
  static API_BASE = process.env.REACT_APP_API_URL;
  static queryClient: QueryClient = new QueryClient();

  /**
   * Fetch user data by user ID.
   * @param userId - The ID of the user.
   * @returns A Promise resolving to the user data.
   */
  static async getUserDataById(userId: number): Promise<User> {
    const endpointURL = API.API_BASE + `users/${userId}`;
    return API.GET(endpointURL);
  }

  /**
   * Load all posts with an optional filter.
   * @param filter - An optional filter for posts.
   * @returns A Promise resolving to the loaded posts.
   */
  static async loadAllPosts(filter: string) {
    let endpointURL = `${API.API_BASE}posts` + filter;
    return API.GET(endpointURL, async (response: Response) => {
      if (response.ok) {
        const parsedData = await response.json();
        const totalPages = parseInt(response.headers.get("X-Wp-Totalpages") ?? "0");
        const totalPosts = parseInt(response.headers.get("X-Wp-Total") ?? "0");
        return { data: parsedData, totalPages, totalPosts };
      }
    });
  }

  /**
   * Load a single post with an optional filter.
   * @param filter - An optional filter for the post.
   * @returns A Promise resolving to the loaded post.
   */
  static async loadPost(filter: string) {
    let endpointURL = `${API.API_BASE}posts` + filter;
    return API.GET(endpointURL);
  }

  /**
   * Load a page by its slug with an optional filter.
   * @param filter - An optional filter for the page slug.
   * @returns A Promise resolving to the loaded page.
   */
  static async loadPageSlug(filter: string) {
    let endpointURL = `${API.API_BASE}pages?` + filter + `&acf_format=standard`;
    return API.GET(endpointURL);
  }

  /**
   * Load a page by its ID.
   * @param id - The ID of the page.
   * @returns A Promise resolving to the loaded page.
   */
  static async loadPage(id: number) {
    let endpointURL = `${API.API_BASE}pages/` + id + `?acf_format=standard`;
    return API.GET(endpointURL);
  }

  /**
   * Load all pages.
   * @returns A Promise resolving to the loaded pages.
   */
  static async loadAllPages() {
    let endpointURL = `${API.API_BASE}pages?acf_format=standard`;
    return API.GET(endpointURL);
  }

  /**
   * Search for posts based on a query.
   * @param query - The search query.
   * @returns A Promise resolving to the search results.
   */
  static async searchPosts(query: any) {
    const endpointURL = `${API.API_BASE}posts?search=${query}`;
    return API.GET(endpointURL);
  }

  /**
   * Generalized method for making GET requests.
   * @param endpointURL - The URL of the API endpoint.
   * @param handler - Optional response handler function.
   * @returns A Promise resolving to the fetched data.
   */
  static async GET(endpointURL: string, handler?: (response: Response) => Promise<unknown>) {
    const cachedData = API.queryClient.getQueryData(endpointURL);
    if (cachedData) {
      return cachedData;
    }

    const data = await API.queryClient.fetchQuery(endpointURL, async () => {
      try {
        const response = await fetch(endpointURL);
        let data;
        if (handler) {
          data = handler(response);
        } else {
          if (response.ok) {
            data = await response.json();
          }
        }

        API.queryClient.setQueryData(endpointURL, data);
        return data;
      } catch (error) {
        throw new Error("Failed to fetch user information");
      }
    });

    return data;
  }
}
