import React from "react";

export default function DestacadoItem({ texto }) {

  return (
<div className="slide">
<p>{texto}</p>
</div>
  );
}
