import React, { Fragment } from "react";
import VideoBackground from "../../components/VideoBackground/VideoBackground";
import streamingVideo from "../../assets/video/0118.mp4";
import Section from "../../components/Section/Section";
import FakePlayer from "../../components/streaming/FakePlayer/FakePlayer";
import StreamTimeLine from "../../components/streaming/StreamTimeLine/StreamTimeLine";
import FeaturedBox from "../../components/FeaturedBox/FeaturedBox";
import "./styles.css";
import "./responsive.css";
import WebSection from "../../components/WebSection/WebSection";
import useDataProvider from "../../hooks/UseDataProvider";
import PageProvider from "../../data/provider/PageProvider";
import { StreamingPage } from "../../data/models/StreamingPage";
import LoadingDots from "../../components/LoadingDots/LoadingDots";
import useHistoryNavigator from "../../hooks/useHistoryNavigator";
import { HomeSection } from "../../types/Types";
import LiveOnDemand from "./Sections/LiveOnDemand/LiveOnDemand";
import Providers from "./Sections/Providers/Providers";
import useThemeColor from "../../hooks/useThemeColor";
import Up from "../../components/Up/Up";

export default function Streaming() {
  const navigate = useHistoryNavigator();
  useThemeColor();
  const { data } = useDataProvider(new PageProvider<StreamingPage>(process.env.REACT_APP_ID_STREAMING!));

  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    navigate("/streaming");
  };

  const timelineData = data?.acf.contenido;

  let firstHalf = timelineData?.slice(0, Math.ceil(timelineData.length / 2));
  let secondHalf = timelineData?.slice(Math.ceil(timelineData.length / 2));

  return (
    <WebSection id="streaming" path={[HomeSection.STREAMING]} onClick={handleClick}>
      {data ? (
        <>
          <Fragment>
            <VideoBackground source={streamingVideo} />
            <Section>
              <Section.Content>
                <div className="section__title streaming_title">
                  <div className="sticky">{data?.acf.titular}</div>
                </div>
                <LiveOnDemand data={data} />
                <StreamTimeLine timeline={firstHalf} />
                <Providers data={data} />
                <StreamTimeLine timeline={secondHalf} />
                <FeaturedBox className="next">
                  <FeaturedBox.RightContent>Tu streaming ; )</FeaturedBox.RightContent>
                </FeaturedBox>
                <FakePlayer />
              </Section.Content>
            </Section>
            <Up slug="streaming"/>
          </Fragment>
        </>
      ) : (
        <LoadingDots />
      )}

    </WebSection>
  );
}
