import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { HomeSection } from "../types/Types";

export default function useActiveSection(paths: HomeSection[]) {
  const [activeSection, setActiveSection] = useState(false);
  const { pathname } = useLocation();


  useEffect(() => {
    calculatePath();
  }, [pathname]);

  const calculatePath = () => {
    if (pathname && paths.length > 0) {
      if (pathname) {
        const clearPathName = pathname.split("/")[1].replace("/", "");
        for (let path of paths) {
          if (clearPathName && path == HomeSection.HOME) {
            setActiveSection(true);
            return;
          }

          if (clearPathName && path.includes(clearPathName)) {
            setActiveSection(true);
            break;
          }

          if (
            clearPathName &&
            path == HomeSection.NEWS &&
            !Object.values(HomeSection).includes(clearPathName as HomeSection)
          ) {
            setActiveSection(true);
            return;
          }

          setActiveSection(false);
        }
      }
    }
  };

  return { activeSection };
}
