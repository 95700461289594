import React from "react";
import './styles.css';
import useHistoryNavigator from "../../hooks/useHistoryNavigator";

export default function LogoHome() {
    const navigate = useHistoryNavigator();
    
    const handleClick = () => {
        navigate("/home");
    };

    return (
        <button className="transparent-button-logo" onClick={handleClick}/>
    );

}