import React from "react";
import StreamTimeLineHit from "../StreamTimeLineHit/StreamTimeLineHit";
import "./styles.css";
import "./responsive.css";


export default function StreamTimeLine({ timeline }) {






  return (
    <div className="stream-timeline">
      {timeline.map((item, index) => (
        <StreamTimeLineHit
          key={index}
          imagen={item.imagen}
          year={item.year}
          titular={item.titular}
          descripcion={item.descripcion}
        />
      ))}
    </div>
  );
}
